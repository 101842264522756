import groq from 'groq'

const linkableDocumentFields = groq`
  _type,
  slug,
  language,
  _id,
  country,
  'atsConnectionSlug': coalesce(atsTypeOfRole->slug.current, atsDepartment->slug.current)
`

const linkableDocumentObject = groq`{${linkableDocumentFields}}`
const linkableDocumentObjectWithTitle = groq`{ title, ${linkableDocumentFields} }`
const referenceWithLabelFields = groq`
  params,
  ...(ref->
    {
      defined(language) && language == $language => {'ref': ${linkableDocumentObjectWithTitle}},

      defined(language) && language != $language => {
        'ref': *[_type == ^._type && translationId == ^.translationId && language == $language] | order(_updatedAt desc) [0]
        ${linkableDocumentObjectWithTitle}
      },
    } |
    {
      'label': coalesce(^.label[$language], string(^.label), ref.title[$language], string(ref.title)),
      ref
    }
  )
`
const referenceWithLabelObject = groq`{${referenceWithLabelFields}}`
const urlWithLabelObject = groq`{label, href}`
const filterCountryAndLanguage = groq`(language==$language && country==$country)`
const filterDepartment = groq`$officeDepartmentId != null && $officeDepartmentId == atsDepartment->internalLookUp`
const filterTypeOfRole = groq`$shopRoleId != null && $shopRoleId == atsTypeOfRole->internalLookUp`

const defaultPageObject = groq`
  ${linkableDocumentFields},
  _updatedAt,
  _createdAt,
  country,
  title
`

const headingObject = groq`{
  _key,
  _type,
  style,
  markDefs,
  children
}`

const imageObject = groq`{..., 'alt': coalesce(alt[$language], string(alt))}`

const inspirationalContentObject = groq`{
  title,
  description,
  items[] {
    ref->{
      hero.heroElementSelector[0]._type == 'heroCollage' => {
        'heroTypeImage': hero.heroElementSelector[0].images[0] ${imageObject}
      },
      hero.heroElementSelector[0]._type == 'heroImage' => {
        'heroTypeImage': hero.heroElementSelector[0] ${imageObject}
      },
      hero.heroElementSelector[0]._type == 'heroVideo' => {
        'heroTypeImage': hero.heroElementSelector[0].poster ${imageObject}
      },
      ${linkableDocumentFields},
      relatedContentImage ${imageObject},
      image ${imageObject},
      title,
    },
    params,
    _key,
    imageOverwrite ${imageObject},
    titleOverwrite,
  }
}`

const contentSimpleObject = groq`{
  ...,
  markDefs[] {
    ...,
    _type == 'internalLink' => { ref->${linkableDocumentObject}, params }
  }
}`

const developmentInformationObject = groq`{
  body,
  footnote,
  backgroundHeading,
  backgroundImage ${imageObject},
  button ${referenceWithLabelObject},
}`

const retailOrOfficeJobsObject = groq`{
  heading[] ${headingObject},
  description,
  items[] {
    _key,
    image ${imageObject},
    title,
    subheader,
    link ${referenceWithLabelObject},
  }
}`

const imageWithText = groq`
  image ${imageObject},
  position,
  aspectRatio,
  link ${referenceWithLabelObject},
  blocks[] ${contentSimpleObject}
`

const imageWithTextObject = groq`{${imageWithText}}`
const imageWithTextAndGradientObject = groq`{${imageWithText}, gradient}`

const testimonialObject = groq`{
  name,
  quote,
  profilePicture
}`

const brandInformationObject = groq`{
  subHeader,
  title,
  slides[] {
    _key,
    image ${imageObject},
    description
  }
}`

const articleItemObject = groq`{
  category,
  title,
  slug,
  image,
  cardSettings,
}`

const jobSliderObject = groq`{
  ...(ref->{
    'title': titleAndLabel[$language].title,
    'label': titleAndLabel[$language].label,
    filters
  })
}`

const cardListObject = groq`{
  slides[] {
    _key,
    subHeader,
    title,
    image ${imageObject},
    button ${referenceWithLabelObject},
  }
}`

const videoObject = groq`{
  'title': coalesce(title[$language], string(title)),
  'url': coalesce(url[$language], string(url)),
  poster ${imageObject}
}`
const worldOfRitualsObject = groq`{
  title,
  items[] {
    _key,
    icon,
    value,
    shortDescription
  }
}`

const careerSlides = groq`{
  _key,
  title,
  toggle,
  toggle == 'image' => { image ${imageObject} },
  toggle == 'video' => { video },
  toggle == 'videoPlayer' => { videoPlayer },
  description,
  button ${referenceWithLabelObject}
}`

const linksObject = groq`{
  _type == 'internalLinkWithLabel' => ${referenceWithLabelObject},
  _type == 'externalLinkWithLabel' => ${urlWithLabelObject}
}`

const littleActsOfKindnessObject = groq`{
  title,
  content,
  links[] ${linksObject},
  image ${imageObject}
}`

const careerSliderObject = groq`{
  heading,
  subHeading,
  slides[] ${careerSlides}
}`

const videoGroupRepeaterObject = groq`{
  videoGroups[] {
    title,
    content[] ${contentSimpleObject},
    videoItems[] {sequenceTitle, videoTitle, video ${videoObject}}
  }
}`

const faqObject = groq`{faq[] { _key, question, answer[] ${contentSimpleObject}}}`

const ctaWithOptionalImageObject = groq`{
  title,
  content,
  image ${imageObject},
  'links': {
    ctaOne ${referenceWithLabelObject},
    ctaTwo ${referenceWithLabelObject}
  },
  styleContextName
}`

const playlistObject = groq`{ _key, title, url}`

const contentObject = groq`{
  ...,
  _type == 'blocks' => ${contentSimpleObject},
  _type == 'brandInformation' => ${brandInformationObject},
  _type == 'developmentInformation' => ${developmentInformationObject},
  _type == 'inspirationalContent' => ${inspirationalContentObject},
  _type == 'littleActsOfKindness' => ${littleActsOfKindnessObject},
  _type == 'imageWithText' => ${imageWithTextAndGradientObject},
  _type == 'testimonial' => ${testimonialObject},
  _type == 'cardList' => ${cardListObject},
  _type == 'image' => ${imageObject},
  _type == 'careerSlider' => ${careerSliderObject},
  _type == 'video' => ${videoObject},
  _type == 'worldOfRituals' => ${worldOfRitualsObject},
  _type == 'videoGroupRepeater' => ${videoGroupRepeaterObject},
  _type == 'faq' => ${faqObject},
  _type == 'ctaWithOptionalImage' => ${ctaWithOptionalImageObject},
  _type == 'playlist' => ${playlistObject},
  _type == 'jobSlider' => ${jobSliderObject},

  markDefs[] {
    ...,
    _type == 'internalLink' => { ref->${linkableDocumentObject}, params }
  }
}`

const singleDocumentFilter = groq`
  *[_type == $type && ${filterCountryAndLanguage} && slug.current == $slug] |
  order(_updatedAt desc) [0]
`

const lifeAtRitualsItemsObject = groq`{
  _type,
  _key,
  _type == 'video' => @->{video ${videoObject}},
  _type == 'imageRef' => @->{image ${imageObject}},
  _type == 'article' => @->${articleItemObject},
}`

const relatedArticlesObject = groq`{
  title,
  description,
  items[] -> { title, image ${imageObject}, ${linkableDocumentFields} }
}`

const heroVideoObject = groq`{
  url,
  title,
  urls {quality1080, quality720, quality540, quality360},
  image ${imageObject}
}`

const timelineObject = groq`{
  date,
  title,
  chooseMedia,
  chooseMedia == 'image' => { image ${imageObject} },
  chooseMedia == 'video' => { video },
  content[] ${contentObject}
}`

const heroObject = groq`{
  heading[] ${headingObject},
  button ${referenceWithLabelObject},
  'type': heroElementSelector[0]._type,

  heroElementSelector[0]._type == 'heroIntroduction' => {
    'introduction': heroElementSelector[0].introduction
  },

  heroElementSelector[0]._type == 'heroCollage' => {
    'collage': {
      'images': heroElementSelector[0].images[] ${imageObject}
    }
  },

  heroElementSelector[0]._type == 'heroImage' => {
    'image': heroElementSelector[0] ${imageObject}
  },

  heroElementSelector[0]._type == 'heroVideo' => {
    'video': heroElementSelector[0]
  }
}`

const faqFieldsObject = groq`{
  ${defaultPageObject},
  hero ${heroObject},
  faq ${faqObject},
  slider ${inspirationalContentObject},
  seo,
  'translations': ${translations(linkableDocumentObjectWithTitle)},
  'alternates': ${alternates(linkableDocumentObjectWithTitle)},
}`

const benefitsObject = groq`{
  _id,
  icon,
  'title': title[$language],
  country,
  language
}`

const benefitsFieldsObject = groq`{
  ${defaultPageObject},
  testimonial,
  hero ${heroObject},
  content[] ${contentObject},
  benefits[]->${benefitsObject},
  growth ${imageWithTextObject},
  seo,
  'translations': ${translations(linkableDocumentObjectWithTitle)},
  'alternates': ${alternates(linkableDocumentObjectWithTitle)},
}`

const growthFieldsObject = groq`{
  ${defaultPageObject},
  hero ${heroObject},
  content[] ${contentObject},
  seo,
  'translations': ${translations(linkableDocumentObjectWithTitle)},
  'alternates': ${alternates(linkableDocumentObjectWithTitle)},
}`

const pageFieldsObject = groq`{
  ${defaultPageObject},
  hero ${heroObject},
  pageTemplate,
  content[] ${contentObject},
  seo,
  'translations': ${translations(linkableDocumentObjectWithTitle)},
} | {
  ...,
  'alternates': translations
}`

const homePageFieldsObject = groq`
  ${defaultPageObject},
  introduction,
  cta ${ctaWithOptionalImageObject},
  video ${heroVideoObject},
  content[] ${contentObject},
  retailOrOfficeJobs ${retailOrOfficeJobsObject},
  seo,
  'translations': ${translations(linkableDocumentObjectWithTitle)},
  'alternates': ${alternatesHome(linkableDocumentObjectWithTitle)},
`

const menuItemObject = groq`{
  _key,
  ${referenceWithLabelFields},
}`

export const snippets = {
  linkableDocumentFields,
  linkableDocumentObjectWithTitle,
  linkableDocumentObject,
  filterCountryAndLanguage,
  projections: {
    menu: groq`{menuItems[] {
      'menuItemType':menuItemTypeChoice,
      'items': menuItems[] ${menuItemObject},
      'protectedItems': protectedMenuItems[] ${menuItemObject},
      'item': ${referenceWithLabelObject}
    }}`,
    footerMenu: groq`{
      'column1': footerMenuColumn1[] ${referenceWithLabelObject},
      'column2': footerMenuColumn2[] ${referenceWithLabelObject},
      'column3': footerMenuColumn3[] ${referenceWithLabelObject},
      showExtraNotice
    }`,
    homeCHO: groq`{${homePageFieldsObject}}`,
    homeLHO: groq`{${homePageFieldsObject}}`,
    homeRetailOnly: groq`{${homePageFieldsObject}}`,
    jobsOverview: groq`{
      ${defaultPageObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    shopRolesFilterList: groq`{
      'title': title[$language],
      slug,
      internalLookUp
    }`,
    officeDepartmentsFilterList: groq`{
      'title': title[$language],
      slug,
      internalLookUp
    }`,
    jobIntroAndClosing: groq`{
      country,
      introduction,
    }`,
    jobIntroAndClosingFallback: groq`{
      introductionOffice,
      introductionShop,
    }`,
    jobSettings: groq`{
      enablePortfolioField,
    }`,
    jobImages: groq`{
      country,
      images[] ${imageObject},
    }`,
    jobImagesFallback: groq`{
      imagesOffice[] ${imageObject},
      imagesShop[] ${imageObject},
    }`,
    jobBenefits: groq`{
      title,
      country,
      benefits[]->{icon,'description': description[$language]},
    }`,
    jobBenefitsFallback: groq`{
      benefitsOffice[]->{icon,'description': description[$language]},
      benefitsShop[]->{icon,'description': description[$language]},
    }`,
    jobBenefitsXmlFeeds: groq`{
      title,
      country,
      'atsLookupDepartment': atsDepartment->internalLookUp,
      'atsLookupTypeOfRole': atsTypeOfRole->internalLookUp,
      benefits[]->{description},
    }`,
    jobBenefitsFallbackXmlFeeds: groq`{
      country,
      benefitsOffice[]->{description},
      benefitsShop[]->{description},
    }`,
    jobIntroAndClosingXmlFeeds: groq`{
      country,
      language,
      'atsLookupDepartment': atsDepartment->internalLookUp,
      'atsLookupTypeOfRole': atsTypeOfRole->internalLookUp,
      introduction,
    }`,
    jobIntroAndClosingFallbackXmlFeeds: groq`{
      country,
      language,
      introductionOffice,
      introductionShop,
    }`,
    jobRelatedContent: groq`{
      country,
      relatedContent[] ${referenceWithLabelObject},
    }`,
    workWithHeartAndSoulPage: groq`{
      ${defaultPageObject},
      floatingImages[] ${imageObject},
      floatingText[],
      heroVideo ${videoObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    timelinePage: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      timeline[] ${timelineObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    lifeAtRitualsArticle: groq`{
      ${defaultPageObject},
      introduction,
      category,
      image ${imageObject},
      content[] ${contentObject},
      relatedArticles ${relatedArticlesObject},
      seo,
      cardSettings,
      ${linkableDocumentFields},
      'translations': ${translations(linkableDocumentObjectWithTitle)},
    } | {
      ...,
      'alternates': translations,
    }`,
    lifeAtRitualsImage: groq`{
      'type': _type,
      _updatedAt,
      image ${imageObject}
    }`,
    lifeAtRitualsVideo: groq`{
      'type': _type,
      _updatedAt,
      sequenceTitle,
      videoTitle,
      video ${videoObject}
    }`,
    aboutPage: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      inspirationalContent ${inspirationalContentObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    instorePage: groq`{
      ${linkableDocumentFields},
      _updatedAt,
      _createdAt,
      country,
      heroImage ${imageObject},
      content[] ${contentObject},
    }`,
    shopsPage: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      introduction[] ${contentObject},
      careerSlider ${careerSliderObject},
      testimonial ${testimonialObject},
      imageWithText ${imageWithTextObject},
      content[] ${contentObject},
      developmentInformation ${developmentInformationObject},
      brandInformation ${brandInformationObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    shopsRolePage: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      atsTypeOfRole->{
        internalLookUp,
        slug,
        'title': title[$language]
      },
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternatesShopRolePage(linkableDocumentObjectWithTitle)},
    }`,
    shopsGrowthPage: growthFieldsObject,
    shopsBenefitsPage: benefitsFieldsObject,
    shopsFAQPage: faqFieldsObject,

    officesPage: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      introduction[] ${contentObject},
      testimonial ${testimonialObject},
      imageWithText ${imageWithTextObject},
      departments[]->{
        title,
        slug,
        'image': overviewCardImage ${imageObject},
        'id': _id,
        'atsDepartment': atsDepartment->slug.current
      },
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    officesDepartmentPage: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      inspirationalContent ${inspirationalContentObject},
      atsDepartment->{
        internalLookUp,
        slug,
        'title': title[$language]
      },
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternatesOfficesDepartmentPage(linkableDocumentObjectWithTitle)},
    }`,
    officesGrowthPage: growthFieldsObject,
    officeBenefitsPage: benefitsFieldsObject,
    officeFAQPage: faqFieldsObject,
    lifeAtRitualsPage: groq`{
      ${linkableDocumentFields},
      hero ${heroObject},
      items[]${lifeAtRitualsItemsObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    mindOasis: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    houseOfRituals: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    internsPage: groq`{
      ${defaultPageObject},
      hero ${heroObject},
      content[] ${contentObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    workWithHeartAndSoulChildPage: pageFieldsObject,
    page: pageFieldsObject,
    protectedPage: pageFieldsObject,
    privacyPage: groq`{
      ${defaultPageObject},
      content[] ${contentObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    impressumPage: groq`{
      ${defaultPageObject},
      content[] ${contentObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    cookiePolicy: groq`{
      ${defaultPageObject},
      content[] ${contentObject},
      seo,
      'translations': ${translations(linkableDocumentObjectWithTitle)},
      'alternates': ${alternates(linkableDocumentObjectWithTitle)},
    }`,
    notFoundPage: groq`{
      country,
      image ${imageObject},
      video ${heroVideoObject}
    }`
  },
  filterDepartment,
  filterTypeOfRole,
  localSingletonDocument(type, projection, { language, country }) {
    return [
      groq`
          *[_type == $type && ${filterCountryAndLanguage}] |
          order(_updatedAt desc) [0] ${projection}
        `,
      { type, language, country }
    ]
  },
  countrySingletonDocument(type, projection, { country, language }) {
    return [
      groq`
          *[_type == $type && country == $country] |
          order(_updatedAt desc) [0] ${projection}
        `,
      { type, country, language }
    ]
  },
  globalSingletonDocument(type, projection, { language }) {
    return [
      groq`
          *[_type == $type] |
          order(_updatedAt desc) [0] ${projection}
        `,
      { type, language }
    ]
  },
  document(type, projection, { language, country, slug }) {
    return [
      groq`${singleDocumentFilter} ${projection}`,
      { type, language, slug, country }
    ]
  },
  multipleLocalDocuments(type, projection, { language, country }) {
    return [
      groq`
          *[_type == $type && ${filterCountryAndLanguage}] |
          order(_updatedAt desc) ${projection}
        `,
      { type, language, country }
    ]
  },
  multipleCountryDocuments(type, projection, { language, country }) {
    return [
      groq`
          *[_type == $type && country == $country] |
          order(_updatedAt desc) ${projection}
        `,
      { language, type, country }
    ]
  },
  multipleGlobalDocuments(type, projection, { language, country }) {
    return [
      groq`
          *[_type == $type] |
          order(_updatedAt desc) ${projection}
        `,
      { language, type, country }
    ]
  },
  multipleGlobalDocumentsFilterConnectionCountries(type, projection, { language, country }) {
    return [
      groq`
          *[_type == $type && $country in availableInCountries] |
          order(_updatedAt desc) ${projection}
        `,
      { language, type, country }
    ]
  },
  translations
}

function translations(projection) {
  return groq`
    *[
      _id != ^._id &&
      _type == ^._type &&
      translationId == ^.translationId
    ]
    ${projection}
  `
}

function alternates(projection) {
  return groq`
    *[
      _id != ^._id &&
      _type == ^._type
    ]
    ${projection}
  `
}

function alternatesShopRolePage(projection) {
  return groq`
    *[
      _id != ^._id &&
      _type == ^._type &&
      atsTypeOfRole._ref == ^.atsTypeOfRole._ref
    ]
    ${projection}
  `
}

function alternatesOfficesDepartmentPage(projection) {
  return groq`
    *[
      _id != ^._id &&
      _type == ^._type &&
      atsDepartment._ref == ^.atsDepartment._ref
    ]
    ${projection}
  `
}

function alternatesHome(projection) {
  return groq`
    *[
      _id != ^._id &&
      _type in ['homeCHO', 'homeLHO', 'homeRetailOnly']
    ]
    ${projection}
  `
}
